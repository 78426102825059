import React, { ReactNode } from "react";

interface AuthLayoutProps {
  authAction: string;
  children: ReactNode;
}
const AuthLayout: React.FC<AuthLayoutProps> = ({ authAction, children }) => {
  return (
    <div className="flex h-screen items-center justify-center bg-[url('/public/login-background.png')] bg-cover bg-center">
      <div className="mx-auto flex w-2/3 flex-col gap-6 rounded-3xl bg-white p-10 shadow-xl md:w-1/2 xl:w-2/5 2xl:w-1/3">
        <img src="/moodee-v2.png" className="mx-auto" alt="logo" />
        <h2 className="text-2xl font-semibold">{authAction}</h2>
        {children}
        {/* 
        <div>
          <div className="relative mb-2">
            <div className="absolute inset-0 flex items-center">
              <div className="w-full border-t border-gray-300"></div>
            </div>
            <div className="relative flex justify-center text-sm">
              <span className="bg-white px-6 font-light text-gray-400">
                Se connecter avec
              </span>
            </div>
          </div>
                    <Button type="button" variant="outline" size="full" hover="jumping">
            <IconBrandGoogle className="text-blue" />
          </Button> 
        </div>*/}
      </div>
    </div>
  );
};

export default AuthLayout;
