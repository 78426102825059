import { ResponsiveLine } from "@nivo/line";
import {
  IconBrandInstagram,
  IconBrandTiktok,
  IconNews,
  IconTools,
} from "@tabler/icons-react";
import React, { useEffect, useState } from "react";
import { useUser } from "../context/UserContext";
import { getSentimentGraph } from "../lib/analysis";
import { getAllFocusesByUser } from "../lib/focus";
import { getAllSocialMedias } from "../lib/socialMedias";
import { Focus, GraphData, SocialMedia } from "../types";
import Header from "./Header";
import { Button } from "./ui/button";
import { Input } from "./ui/input";
import { Label } from "./ui/label";
import { MultiSelect } from "./ui/multi-select";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "./ui/select";

const Dashboard: React.FC = () => {
  const { user, languages } = useUser();
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [myFocuses, setMyFocuses] = useState<Focus[]>([]);
  const [focusId, setFocusId] = useState<string>("");
  const [startDate, setStartDate] = useState<string>("");
  const [endDate, setEndDate] = useState<string>("");
  const [socialMedias, setSocialMedias] = useState<SocialMedia[]>([]);
  const [socialMediaIds, setSocialMediaIds] = useState<string[]>([]);
  const [, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [graphData, setGraphData] = useState<GraphData[]>([]);
  const [groupedData, setGroupedData] = useState<{ [key: string]: number }>({});

  useEffect(() => {
    const loadMyFocuses = async () => {
      try {
        const data = await getAllFocusesByUser();
        setMyFocuses(data);
      } catch (err: any) {
        setError(err.message || "Failed to fetch focuses.");
      } finally {
        setLoading(false);
      }
    };

    loadMyFocuses();
  }, []);

  useEffect(() => {
    const loadSocialMedias = async () => {
      try {
        const data = await getAllSocialMedias();
        setSocialMedias(data);
      } catch (err: any) {
        setError(err.message || "Failed to fetch social media platforms.");
      }
    };

    loadSocialMedias();
  }, []);

  useEffect(() => {
    if (!Array.isArray(graphData) || graphData.length === 0) return; // Vérifier si graphData est un tableau

    const grouped: { [key: string]: number } = graphData.reduce(
      (acc: { [key: string]: number }, current: GraphData) => {
        const date = current.date.toISOString().split("T")[0]; // Convertir la date en chaîne YYYY-MM-DD
        if (!acc[date]) {
          acc[date] = 0;
        }
        acc[date] += current.sentiment_score; // Utiliser directement le score
        return acc;
      },
      {} as { [key: string]: number },
    );
    setGroupedData(grouped);
  }, [graphData]);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      const response = await getSentimentGraph({
        focus_id: focusId,
        start_date: startDate,
        end_date: endDate,
        social_media_ids: socialMediaIds,
      });

      // Vérifier que les données de sentiment_data sont bien un tableau
      if (response && Array.isArray(response.sentiment_data)) {
        // Extraire et formater les données de sentiment_data
        const formattedData = response.sentiment_data.map((item: any) => ({
          comment_id: item.comment_id,
          sentiment_score: parseFloat(item.sentiment_score),
          date: new Date(item.date),
        }));
        setGraphData(formattedData);
      } else {
        // Si le format de données est incorrect, définissez une liste vide et affichez un message d'erreur
        setGraphData([]);
        setError("Invalid data format received from API.");
      }
    } catch (err: any) {
      // Gérer les erreurs en définissant le message d'erreur
      setError(err.message || "Failed to fetch sentiment data.");
    }
  };

  const dates = Object.keys(groupedData);
  const scores = dates.map((date) => groupedData[date]);

  const formatDataForNivo = () => {
    return [
      {
        id: "Sentiment Score Evolution",
        data: dates.map((date, index) => ({
          x: date,
          y: scores[index],
        })),
      },
    ];
  };
  return (
    <>
      <div className="rounded-5xl rounded-tr-none bg-blue p-8">
        <Header
          title="Dashboard"
          languages={languages}
          searchQuery={searchQuery}
          setSearchQuery={setSearchQuery}
          isDashboard={true}
        />

        <p>Welcome {user?.first_name}</p>
      </div>

      <div className="p-8">
        {/* Formulaire pour envoyer une requête au endpoint sentiment_graph */}
        <form
          onSubmit={handleSubmit}
          className="flex w-1/2 flex-col gap-4 rounded-2xl border border-blue p-4"
        >
          <div className="flex items-center gap-3">
            <Select onValueChange={setFocusId} value={focusId}>
              <SelectTrigger className="flex w-full items-center justify-between rounded-full border border-gray-100 bg-inherit p-6 text-sm capitalize hover:bg-inherit">
                <SelectValue placeholder="Produit" />
              </SelectTrigger>
              <SelectContent>
                {myFocuses.map((focus) => (
                  <SelectItem
                    key={focus.focus_id}
                    value={focus.focus_id}
                    className="focus:bg-orange-light"
                  >
                    {focus.name}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
            <MultiSelect
              options={socialMedias.map((social) => ({
                label: social.name,
                value: social.social_media_id,
                icon: (() => {
                  if (social.formatted_name === "tiktok") {
                    return IconBrandTiktok;
                  } else if (social.formatted_name === "instagram") {
                    return IconBrandInstagram;
                  } else if (social.formatted_name === "google_news") {
                    return IconNews;
                  } else {
                    return IconTools;
                  }
                })(),
              }))}
              onValueChange={setSocialMediaIds}
              defaultValue={socialMediaIds}
              placeholder="Media"
              variant="orange"
              animation={2}
              maxCount={3}
            />
          </div>

          <div className="flex items-center gap-3">
            <div className="relative w-full">
              <Input
                type="date"
                value={startDate}
                onChange={(e) => setStartDate(e.target.value)}
                required
              />
              <Label design="auth">Start Date</Label>
            </div>
            <div className="relative w-full">
              <Input
                type="date"
                value={endDate}
                onChange={(e) => setEndDate(e.target.value)}
                required
              />
              <Label design="auth">End Date</Label>
            </div>
          </div>

          <Button type="submit">Submit</Button>
        </form>

        {graphData.length > 0 && (
          <div style={{ height: 400 }}>
            <ResponsiveLine
              data={formatDataForNivo()}
              margin={{ top: 50, right: 110, bottom: 50, left: 60 }}
              xScale={{ type: "point" }}
              yScale={{
                type: "linear",
                min: "auto",
                max: "auto",
                stacked: true,
                reverse: false,
              }}
              axisTop={null}
              axisRight={null}
              axisBottom={{
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 45,
                legend: "Date",
                legendOffset: 36,
                legendPosition: "middle",
              }}
              axisLeft={{
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                legend: "Sentiment Score",
                legendOffset: -40,
                legendPosition: "middle",
              }}
              colors={{ scheme: "nivo" }}
              pointSize={10}
              pointColor={{ theme: "background" }}
              pointBorderWidth={2}
              pointBorderColor={{ from: "serieColor" }}
              pointLabelYOffset={-12}
              useMesh={true}
              legends={[
                {
                  anchor: "bottom-right",
                  direction: "column",
                  justify: false,
                  translateX: 100,
                  translateY: 0,
                  itemsSpacing: 0,
                  itemDirection: "left-to-right",
                  itemWidth: 80,
                  itemHeight: 20,
                  itemOpacity: 0.75,
                  symbolSize: 12,
                  symbolShape: "circle",
                  symbolBorderColor: "rgba(0, 0, 0, .5)",
                  effects: [
                    {
                      on: "hover",
                      style: {
                        itemBackground: "rgba(0, 0, 0, .03)",
                        itemOpacity: 1,
                      },
                    },
                  ],
                },
              ]}
            />
          </div>
        )}

        {error && <p style={{ color: "red" }}>{error}</p>}
      </div>
    </>
  );
};

export default Dashboard;
