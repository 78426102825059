import React from "react";
import {
  Navigate,
  Route,
  BrowserRouter as Router,
  Routes,
} from "react-router-dom";
import Login from "./components/auth/Login";
import SignUp from "./components/auth/SignUp";
import ChatPage from "./components/Chat";
import FocusDetail from "./components/FocusDetail";
import Layout from "./components/Layout";
import PrivateRoute from "./components/PrivateRoute";
import Products from "./components/Products";
import Settings from "./components/Settings";
import { UserProvider } from "./context/UserContext";
import Dashboard from "./components/Dashboard";

const App: React.FC = () => {
  return (
    <Router>
      <UserProvider>
        <Routes>
          <Route path="/" element={<Navigate to="/products" replace />} />
          <Route element={<Layout />}>
            <Route element={<PrivateRoute />}>
              <Route path="dashboard" element={<Dashboard />} />
              <Route path="chat" element={<ChatPage />} />
              <Route path="products" element={<Products />} />
              <Route path="focus/:slug" element={<FocusDetail />} />
              <Route path="settings" element={<Settings />} />
            </Route>
          </Route>
          <Route path="/auth/login" element={<Login />} />
          <Route path="/auth/signup" element={<SignUp />} />
        </Routes>
      </UserProvider>
    </Router>
  );
};

export default App;
