import { SentimentGraph } from "../types";
import axiosInstance from "./axiosInstance";

type GetSentimentGraphData = {
  focus_id: string;
  start_date: string;
  end_date: string;
  social_media_ids: string[];
};

export const getSentimentGraph = async ({
  focus_id,
  start_date,
  end_date,
  social_media_ids,
}: GetSentimentGraphData): Promise<SentimentGraph> => {
  try {
    const response = await axiosInstance.post(
      "/focus_analysis/sentiment_graph",
      {
        focus_id,
        start_date,
        end_date,
        social_media_ids,
      },
    );
    return response.data;
  } catch (error: any) {
    if (error.response) {
      throw error.response.data;
    } else {
      throw new Error("Error fetching graph data");
    }
  }
};
