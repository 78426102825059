import { Loader } from "lucide-react";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { generateSentiment, generateSummary, getFocusByID } from "../lib/focus";
import { getScrapingsByFocus } from "../lib/scraping";
import { getAllSocialMedias } from "../lib/socialMedias";
import { cn } from "../lib/utils";
import { Focus, Scraping, SocialMedia } from "../types";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "./ui/table";

const FocusDetail: React.FC = () => {
  const location = useLocation();
  const { focusId } = location.state || {};
  const [focus, setFocus] = useState<Focus | null>(null);
  const [socialMedias, setSocialMedias] = useState<SocialMedia[]>([]);
  const [scrapings, setScrapings] = useState<Scraping[]>([]);

  const [isCriticalDataLoading, setIsCriticalDataLoading] =
    useState<boolean>(true);
  const [isSummaryandSentimentLoading, setIsSummaryandSentimentLoading] =
    useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [summary, setSummary] = useState<string | null>(null);
  const [sentiment, setSentiment] = useState<Record<string, any> | null>(null);

  useEffect(() => {
    const loadCriticalData = async () => {
      if (!focusId) {
        setError("No ID provided");
        setIsCriticalDataLoading(false);
        return;
      }

      try {
        setIsCriticalDataLoading(true);
        // Charger les données critiques en parallèle
        const [focusData, socialMediasData, scrapingsData] = await Promise.all([
          getFocusByID(focusId),
          getAllSocialMedias(),
          getScrapingsByFocus(focusId),
        ]);

        // Mettre à jour les états avec les données critiques
        setFocus(focusData);
        setSocialMedias(socialMediasData);
        setScrapings(scrapingsData);
      } catch (err: any) {
        setError(err.message || "Failed to load critical data.");
      } finally {
        setIsCriticalDataLoading(false);
      }
    };

    loadCriticalData();
  }, [focusId]);

  useEffect(() => {
    const loadAdditionalData = async () => {
      if (!focusId) {
        setError("No ID provided");
        setIsSummaryandSentimentLoading(false);
        return;
      }
      try {
        setIsSummaryandSentimentLoading(true);

        // Charger le résumé et le sentiment en parallèle
        const [summaryResponse, sentimentResponse] = await Promise.all([
          generateSummary(focusId),
          generateSentiment(focusId),
        ]);

        // Mettre à jour les états avec le résumé et le sentiment
        setSummary(summaryResponse);
        setSentiment(sentimentResponse);
      } catch (err: any) {
        setError(err.message || "Failed to load additional data.");
      } finally {
        setIsSummaryandSentimentLoading(false);
      }
    };

    loadAdditionalData();
  }, [focusId]);

  const getSocialMediaName = (id: string) => {
    const socialMedia = socialMedias.find((sm) => sm.social_media_id === id);
    return socialMedia ? socialMedia.name : id; // Si le nom est trouvé, le retourner, sinon retourner l'ID
  };

  return (
    <div className="px-12 py-10">
      {isCriticalDataLoading && (
        <Loader className="mx-auto size-16 animate-spin text-blue" />
      )}
      <h1 className="mb-6 text-3xl">{focus?.name}</h1>

      <div className="grid grid-cols-2 gap-3">
        <div className="rounded-3xl bg-orange-light p-4">
          <h2 className="mb-2 text-2xl font-bold">Résumé du produit</h2>
          {summary && !isSummaryandSentimentLoading ? (
            <p>{summary}</p>
          ) : (
            <Loader className="mx-auto size-16 animate-spin text-blue" />
          )}
        </div>

        <div className="rounded-3xl bg-purple-light p-4">
          <h2 className="mb-2 text-2xl font-bold">
            Analyse du sentiment global
          </h2>
          {sentiment && !isSummaryandSentimentLoading ? (
            <>
              <p>Positive Ratio: {sentiment.positive_ratio}</p>
              <p>Negative Ratio: {sentiment.negative_ratio}</p>
              <p>Neutral Ratio: {sentiment.neutral_ratio}</p>
              <p>No Sentiment Ratio: {sentiment.no_sentiment_ratio}</p>
            </>
          ) : (
            <Loader className="mx-auto size-16 animate-spin text-blue" />
          )}
        </div>
      </div>

      {scrapings.length > 0 && (
        <div className="mt-4">
          <h2 className="text-2xl font-bold">Paramétrages</h2>
          <Table>
            <TableHeader>
              <TableRow>
                <TableHead>Date de création</TableHead>
                <TableHead>Statut</TableHead>
                <TableHead>Mots clés</TableHead>
                <TableHead>Réseaux sociaux</TableHead>
                <TableHead>Historique du scraping </TableHead>
              </TableRow>
            </TableHeader>
            <TableBody>
              {scrapings.map((scraping, index) => (
                <TableRow key={index}>
                  <TableCell>
                    {new Date(scraping.created_at).toLocaleDateString("fr-FR", {
                      year: "numeric",
                      month: "2-digit",
                      day: "2-digit",
                    })}
                  </TableCell>
                  <TableCell>
                    <p
                      className={cn(
                        scraping.status === "In Progress" && "bg-orange",
                        scraping.status === "Completed" && "bg-green",
                        scraping.status === "Failed" && "bg-red-500",
                        "w-fit rounded-full px-5 py-0.5",
                      )}
                    >
                      {scraping.status}
                    </p>
                  </TableCell>
                  <TableCell className="flex flex-col gap-1">
                    {scraping.keyword_logic.map((keyword) => (
                      <p className="w-fit rounded-full bg-purple-light px-5 py-0.5">
                        {keyword.join(" - ")}
                      </p>
                    ))}
                  </TableCell>
                  <TableCell>
                    {scraping.social_media_ids?.map((social) => (
                      <p>{getSocialMediaName(social)}</p>
                    ))}
                  </TableCell>
                  <TableCell>
                    depuis le{" "}
                    {new Date(scraping.start_date).toLocaleDateString("fr-FR", {
                      year: "numeric",
                      month: "long",
                      day: "2-digit",
                    })}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </div>
      )}
    </div>
  );
};

export default FocusDetail;
