import {
  IconBrandInstagram,
  IconBrandTiktok,
  IconNews,
  IconTool,
} from "@tabler/icons-react";
import { ChevronRight, PlusIcon, XIcon } from "lucide-react";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useUser } from "../context/UserContext";
import { getAllFocuses, getAllFocusesByUser, getFocusByID } from "../lib/focus";
import { createNewScraping } from "../lib/scraping";
import { getAllSocialMedias } from "../lib/socialMedias";
import { cn, createSlug } from "../lib/utils";
import { Focus } from "../types";
import Header from "./Header";
import { Button } from "./ui/button";
import { Dialog, DialogContent, DialogTitle, DialogTrigger } from "./ui/dialog"; // Import modal components
import { Input } from "./ui/input";
import { Label } from "./ui/label";
import { MultiSelect } from "./ui/multi-select";
import { RadioGroup, RadioGroupItem } from "./ui/radio-group";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "./ui/select";

const Products: React.FC = () => {
  const { languages } = useUser();
  const navigate = useNavigate();
  const [allFocuses, setAllFocuses] = useState<Focus[]>([]);
  const [myFocuses, setMyFocuses] = useState<Focus[]>([]);
  const [socialMedias, setSocialMedias] = useState<any[]>([]);
  const [newScrapingFocusName, setNewScrapingFocusName] = useState<string>("");
  const [newScrapingSocialMedias, setNewScrapingSocialMedias] = useState<
    string[]
  >([]);
  const [newScrapingStartDate, setNewScrapingStartDate] = useState<string>("");
  const [keywords, setKeywords] = useState({
    keyword1: "",
    keyword2: "",
    keyword3: "",
    keyword4: "",
  });

  // State for array of arrays of keywords
  const [newScrapingKeywords, setNewScrapingKeywords] = useState<string[][]>(
    [],
  );
  const [filteredFocuses, setFilteredFocuses] = useState<Focus[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [error, setError] = useState<string | null>(null);
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false); // State to manage modal
  const [selectedOption, setSelectedOption] = useState<string>(""); // Default selection is "Produit existant"

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { id, value } = e.target;
    setKeywords((prev) => ({
      ...prev,
      [id]: value,
    }));
  };

  // Add the keywords array to the main array of keywords
  const handleAddKeywords = () => {
    const { keyword1, keyword2, keyword3, keyword4 } = keywords;

    // Filter out empty values for optional keywords (keyword2 and keyword3)
    const keywordsArray = [keyword1, keyword2, keyword3, keyword4].filter(
      Boolean,
    );

    // Add the new array of keywords to the main array
    setNewScrapingKeywords((prev) => [...prev, keywordsArray]);

    // Clear the input fields
    setKeywords({
      keyword1: "",
      keyword2: "",
      keyword3: "",
      keyword4: "",
    });
  };

  const handleRemoveKeywords = (index: number) => {
    setNewScrapingKeywords((prev) => prev.filter((_, i) => i !== index));
  };

  // Function to validate form fields
  const isFormValid = () => {
    return (
      newScrapingFocusName.trim() !== "" &&
      newScrapingSocialMedias.length > 0 &&
      newScrapingStartDate.trim() !== "" &&
      newScrapingKeywords.length > 0
    );
  };

  // Function to handle the form submission to start the scraping
  const handleStartScraping = async () => {
    if (!isFormValid()) {
      setError("Veuillez remplir tous les champs obligatoires.");
      return;
    }

    setIsLoading(true);
    setError(null);

    const scrapingDateFormatted = new Date(newScrapingStartDate).toISOString();

    try {
      // Use the selected focus name, keywords, and other inputs to call the API
      const newScraping = await createNewScraping(
        newScrapingFocusName, // The name of the focus (from input or select)
        newScrapingKeywords, // Array of keywords arrays
        newScrapingSocialMedias, // Selected social media IDs
        scrapingDateFormatted, // The start date
      );

      const newFocus = await getFocusByID(newScraping.focus_id);
      navigate(`/focus/${createSlug(newFocus.name)}`, {
        state: { focusId: newFocus.focus_id },
      });

      // Handle success (you could display a success message or redirect)
    } catch (err: any) {
      setError(err.message || "An error occurred while creating the scraping.");
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const loadAllFocuses = async () => {
      try {
        const data = await getAllFocuses();
        setAllFocuses(data);
      } catch (err: any) {
        setError(err.message || "Failed to fetch focuses.");
      } finally {
        setIsLoading(false);
      }
    };

    loadAllFocuses();
  }, []);

  useEffect(() => {
    const loadMyFocuses = async () => {
      try {
        const data = await getAllFocusesByUser();
        setMyFocuses(data);
        setFilteredFocuses(data);
      } catch (err: any) {
        setError(err.message || "Failed to fetch focuses.");
      } finally {
        setIsLoading(false);
      }
    };

    loadMyFocuses();
  }, []);

  useEffect(() => {
    const loadSocialMedias = async () => {
      try {
        const data = await getAllSocialMedias();
        setSocialMedias(data);
      } catch (err: any) {
        setError(err.message || "Failed to fetch social media platforms.");
      }
    };

    loadSocialMedias();
  }, []);

  useEffect(() => {
    if (searchQuery.trim() === "") {
      setFilteredFocuses(myFocuses);
    } else {
      const filtered = myFocuses.filter((focus) =>
        focus.name.toLowerCase().includes(searchQuery.toLowerCase()),
      );
      setFilteredFocuses(filtered);
    }
  }, [searchQuery, myFocuses]);

  const hoverColors = [
    "hover:bg-green-light",
    "hover:bg-orange-light",
    "hover:bg-purple-light",
  ];

  const buttonColors = ["bg-green", "bg-orange", "bg-purple"];

  // Return both hover and base colors as a string
  const getColorClass = (index: number) => {
    const hoverColor = hoverColors[index % hoverColors.length];
    const buttonColor = buttonColors[index % buttonColors.length];
    return { hoverColor, buttonColor };
  };

  if (error) {
    return <p>{error}</p>;
  }

  return (
    <div className="p-8">
      <Header
        title="Produits"
        searchQuery={searchQuery}
        setSearchQuery={setSearchQuery}
        languages={languages}
      />

      <Dialog open={isModalOpen} onOpenChange={setIsModalOpen}>
        <DialogTrigger asChild>
          <Button className="ml-auto mt-12 flex rounded-lg font-normal">
            <PlusIcon size={20} className="mr-2" />
            Paramétrer une nouvelle récupération de données
          </Button>
        </DialogTrigger>
        <DialogContent>
          <DialogTitle className="mb-2">
            Paramétrer une nouvelle récupération de données
          </DialogTitle>
          <div className="grid grid-cols-2 gap-x-3 gap-y-5">
            <Label htmlFor="newProductCheckbox" className="mr-2">
              Quel focus voulez-vous analyser ?
            </Label>
            <RadioGroup
              value={selectedOption}
              onValueChange={setSelectedOption} // Update state when user selects an option
              className="mb-2 flex items-center gap-4"
            >
              <div className="flex items-center space-x-2">
                <RadioGroupItem value="newProduct" id="newProduct" />
                <Label htmlFor="newProduct">Nouveau focus</Label>
              </div>
              <div className="flex items-center space-x-2">
                <RadioGroupItem value="existingProduct" id="existingProduct" />
                <Label htmlFor="existingProduct">Focus existant</Label>
              </div>
            </RadioGroup>

            {selectedOption === "newProduct" && (
              <div className="relative col-span-full w-1/2">
                <Input
                  type="text"
                  id="focusName"
                  value={newScrapingFocusName}
                  onChange={(e) => setNewScrapingFocusName(e.target.value)}
                  required
                  className="h-12"
                />
                <Label design="auth" htmlFor="focusName">
                  Nom du produit
                </Label>
              </div>
            )}

            {selectedOption === "existingProduct" && (
              <Select onValueChange={(value) => setNewScrapingFocusName(value)}>
                <SelectTrigger className="col-span-full flex h-12 w-1/2 items-center justify-between rounded-full border border-blue bg-inherit p-6 text-sm capitalize text-dark hover:bg-inherit">
                  <SelectValue placeholder="Choisir un focus existant" />
                </SelectTrigger>
                <SelectContent>
                  {allFocuses.map((focus) => (
                    <SelectItem
                      key={focus.focus_id}
                      value={focus.name}
                      className="focus:bg-orange-light"
                    >
                      {focus.name}
                    </SelectItem>
                  ))}
                </SelectContent>
              </Select>
            )}

            <div className="relative col-span-1">
              <Input
                type="date"
                id="startDate"
                value={newScrapingStartDate}
                onChange={(e) => setNewScrapingStartDate(e.target.value)}
                required
                className="h-12"
              />
              <Label design="auth" htmlFor="startDate">
                Date de départ
              </Label>
            </div>

            <div className="relative col-span-1">
              <MultiSelect
                options={socialMedias.map((social) => ({
                  label: social.name,
                  value: social.social_media_id,
                  icon: (() => {
                    if (social.formatted_name === "tiktok") {
                      return IconBrandTiktok;
                    } else if (social.formatted_name === "instagram") {
                      return IconBrandInstagram;
                    } else if (social.formatted_name === "google_news") {
                      return IconNews;
                    } else {
                      return IconTool;
                    }
                  })(),
                }))}
                onValueChange={setNewScrapingSocialMedias}
                defaultValue={newScrapingSocialMedias}
                placeholder="Sélectionner un ou plusieurs réseaux"
                variant="blue"
                maxCount={5}
                className="col-span-full h-12 border-blue p-0 px-4"
                iconOnly={true}
              />
              <Label design="auth">Réseaux sociaux</Label>
            </div>

            <div className="col-span-full mb-1 flex flex-col text-dark">
              <h3 className="font-semibold text-dark">Mots-clés</h3>
              <h3 className="text-sm font-semibold text-gray-500">
                Instructions
              </h3>
              <p className="text-sm text-gray-500">
                Choisissez un groupe de mots-clés qui seront repérés ensemble
                sur les médias sociaux. Si vous paramétrez plusieurs groupes de
                mots-clés, ils seront traités séparément. Ci-dessous, chaque
                champ ne doit comporter qu'un seul mot-clé. Par exemple, "Air
                Jordan" ne constitue qu'un seul mot-clé. Vous pouvez combiner
                jusqu'à 4 mots-clés maximum.
              </p>
              <p className="text-sm text-gray-500">
                Exemple : Groupe 1: Mot-clé 1 = Air Jordan / Mot-clé 2 = Jordan
                Max Aura 5 / Mot-clé 3 = Nike Groupe 2: Mot-clé 1 = #airjordan /
                Mot-clé 2 = #nike
              </p>
            </div>
            <div className="relative">
              <Input
                type="text"
                id="keyword1"
                value={keywords.keyword1}
                onChange={handleInputChange}
                required
                className="h-12"
              />
              <Label design="auth" htmlFor="keyword1">
                Mot clé 1
              </Label>
            </div>

            {/* Input for Keyword 2 (optional) */}
            <div className="relative">
              <Input
                type="text"
                id="keyword2"
                value={keywords.keyword2}
                onChange={handleInputChange}
                className="h-12"
              />
              <Label design="auth" htmlFor="keyword2">
                Mot clé 2
              </Label>
            </div>

            {/* Input for Keyword 3 (optional) */}
            <div className="relative">
              <Input
                type="text"
                id="keyword3"
                value={keywords.keyword3}
                onChange={handleInputChange}
                className="h-12"
              />
              <Label design="auth" htmlFor="keyword3">
                Mot clé 3
              </Label>
            </div>

            <div className="relative">
              <Input
                type="text"
                id="keyword4"
                value={keywords.keyword4}
                onChange={handleInputChange}
                className="h-12"
              />
              <Label design="auth" htmlFor="keyword3">
                Mot clé 4
              </Label>
            </div>

            <Button
              onClick={handleAddKeywords}
              className="col-span-full mx-auto w-fit p-2 px-8 text-white"
            >
              Valider ce groupe de mots-clés
            </Button>

            {/* Displaying the added keywords arrays */}
            <div className="col-span-full">
              <ul className="flex items-center gap-2">
                {newScrapingKeywords.map((keywordsArray, index) => (
                  <li
                    className="flex w-fit items-center gap-3 rounded-full bg-blue-light py-0.5 pl-4 pr-2 text-sm"
                    key={index}
                  >
                    {keywordsArray.join(" / ")}
                    <button
                      onClick={() => handleRemoveKeywords(index)}
                      className="text-white"
                    >
                      <XIcon className="size-4 rounded-full bg-orange p-0.5 text-dark" />
                    </button>
                  </li>
                ))}
              </ul>
            </div>
          </div>

          <Button
            className="w-full rounded-full bg-green text-dark"
            hover="jumping"
            onClick={handleStartScraping}
            disabled={!isFormValid() || isLoading} // Disable button if form is invalid or during loading
          >
            {isLoading ? "Lancement..." : "Lancer le scraping"}
          </Button>
          {error && <p className="mt-2 text-red-500">{error}</p>}
        </DialogContent>
      </Dialog>

      <div className="mt-12">
        <div className="grid gap-4 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5">
          {filteredFocuses.map((focus, index) => {
            const colorClasses = getColorClass(index); // Get hover and base color
            return (
              <Link
                to={`/focus/${createSlug(focus.name)}`}
                state={{ focusId: focus.focus_id }}
                key={focus.focus_id}
                className={cn(
                  "col-span-1 flex h-36 flex-col justify-between rounded-3xl p-4 shadow-full transition-all duration-300",
                  "bg-white", // Default base color
                  colorClasses.hoverColor, // Hover and button color
                )}
              >
                <div className="flex flex-col gap-1">
                  <p className="text-xs font-light">
                    {new Date(focus.created_at).toLocaleDateString()}
                  </p>
                  <h1 className="text-lg font-bold leading-5">{focus.name}</h1>
                </div>
                <div
                  className={cn(
                    "w-fit rounded-full p-2 text-white shadow-none",
                    colorClasses.buttonColor,
                  )}
                >
                  <ChevronRight size={20} />
                </div>
              </Link>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default Products;
