import {
  IconBrandInstagram,
  IconBrandTiktok,
  IconNews,
  IconTool,
} from "@tabler/icons-react";
import { cn } from "../lib/utils";
import { Conversation } from "../types";

type ConversationSidebarProps = {
  title: string;
  conversations: Conversation[];
  checkYesterday?: boolean;
  checkLastWeek?: boolean; // Optional prop to check for last week
  checkOlderThanWeek?: boolean; // Optional prop to check for older than 1 week
  handleConversationSelect: (conversationId: string) => void;
  selectedConversation: string | undefined;
};

// Function to check if the conversation was updated yesterday
export function isYesterday(date: Date): boolean {
  const today = new Date();

  return date >= new Date(today.setHours(0, 0, 0, 0));
}

// Function to check if the conversation was updated within the last week (2 to 7 days ago)
export function isWithinLastWeek(date: Date): boolean {
  const today = new Date();
  const sevenDaysAgo = new Date();

  sevenDaysAgo.setDate(today.getDate() - 7);

  // Date is between 2 and 7 days ago (inclusive)
  return date >= sevenDaysAgo && date < new Date(today.setHours(0, 0, 0, 0));
}

// Function to check if the conversation is older than 1 week
export function isOlderThanOneWeek(date: Date): boolean {
  const today = new Date();
  const sevenDaysAgo = new Date();

  sevenDaysAgo.setDate(today.getDate() - 7);

  // Date is older than 7 days
  return date < sevenDaysAgo;
}

export default function ChatConversation({
  title,
  conversations,
  checkYesterday = false, // Optional, defaults to false
  checkLastWeek = false, // Optional, defaults to false
  checkOlderThanWeek = false, // Optional, defaults to false
  handleConversationSelect,
  selectedConversation,
}: ConversationSidebarProps) {
  const filtered = conversations
    .filter((conv) => {
      const convDate = new Date(conv.updated_at);
      if (checkYesterday) {
        return isYesterday(convDate); // Only yesterday
      }
      if (checkLastWeek) {
        return isWithinLastWeek(convDate); // 2 to 7 days ago
      }
      if (checkOlderThanWeek) {
        return isOlderThanOneWeek(convDate); // Older than 7 days
      }
      return true; // No filtering if none of the props are passed
    })
    .sort(
      (a, b) =>
        new Date(b.updated_at).getTime() - new Date(a.updated_at).getTime(),
    );

  if (filtered.length === 0) return null;

  return (
    <>
      <li className="text-sm font-semibold text-gray-400">{title}</li>
      {filtered.map((conversation) => (
        <li
          key={conversation.conversation_id}
          className={cn(
            selectedConversation === conversation.conversation_id && "bg-blue",
            "group flex w-full items-center justify-between gap-2 rounded-full px-2 py-1 transition-all duration-300 hover:cursor-pointer hover:bg-blue",
          )}
          onClick={() => {
            handleConversationSelect(conversation.conversation_id);
          }}
        >
          <h3 className="overflow-x-auto whitespace-nowrap text-dark transition-colors duration-300">
            {conversation.focus_names.map((focus, index) => (
              <span
                key={index}
                className={cn(
                  selectedConversation === conversation.conversation_id &&
                    "text-white",
                  "mr-2 inline-block group-hover:text-white",
                )}
              >
                {focus}
                {index < conversation.focus_names.length - 1 && " / "}
              </span>
            ))}
          </h3>
          <div
            className={cn(
              selectedConversation === conversation.conversation_id &&
                "bg-[#D9EBFF] opacity-100",
              "flex items-center justify-between -space-x-2 rounded-full p-1 transition-all duration-300 group-hover:bg-[#D9EBFF] group-hover:opacity-100",
            )}
          >
            {conversation.social_media_names.map((social) => (
              <div
                key={social}
                className="rounded-full bg-white p-1 text-blue shadow"
              >
                {social === "Custom" && <IconTool size={16} />}
                {social === "Instagram" && <IconBrandInstagram size={16} />}
                {social === "TikTok" && <IconBrandTiktok size={16} />}
                {social === "Google News" && <IconNews size={16} />}
              </div>
            ))}
          </div>
        </li>
      ))}
    </>
  );
}
